div.link-result {
  cursor: pointer;
}

.link-result-title {
  color: var(--bs-dark);
}

.link-result-subtitle {
  color: var(--bs-secondary);
}

div.link-result:hover {
  background-color: rgb(240, 240, 240);
}

div.link-result.active {
  background-color: var(--bs-success);
}

div.link-result.active .link-result-title {
  color: var(--bs-light);
}

div.link-result.active .link-result-subtitle {
  color: var(--bs-light);
  opacity: 0.6;
}
