div.array-field {
  -webkit-appearance: none;
  appearance: none;
  background-clip: padding-box;
  background-color: #fff;
  border-radius: 0.25rem;
  border: 1px solid #ced4da;
  color: #212529;
  display: block;
  font-size: 1rem;
  font-weight: 400;
  min-height: calc(3.5rem + 2px);
  line-height: 1.5;
  padding-bottom: 0.625rem;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  padding-top: 2.125rem;
  position: relative;
  width: 100%;
}

div.array-field-label {
  border: 1px solid transparent;
  height: 100%;
  left: 0;
  opacity: 0.65;
  padding: 1rem 0.75rem;
  pointer-events: none;
  position: absolute;
  top: 0;
  transform-origin: 0 0;
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}
