.react-datepicker-wrapper .form-control {
  height: calc(3.5rem + 2px);
  padding: 1rem 0.75rem;
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}

.react-datepicker-wrapper {
  display: block !important;
}

.react-datepicker-wrapper ~ label {
  opacity: 0.65;
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}

.react-datepicker-wrapper ~ .invalid-feedback {
  display: block;
}
