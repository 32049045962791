div.search-results {
  z-index: 1030;
  top: 2.5rem;
  width: min(30rem, 30vw);
  max-height: calc(100vh - 6rem);
  overflow-y: auto;
  overflow-x: hidden;
}

a.search-result:hover {
  background-color: rgb(240, 240, 240);
}

a.search-result .badge {
  margin-top: 0.2rem;
  text-transform: capitalize;
}

.search-result-type {
  width: 4rem;
  min-width: 4rem;
  max-width: 4rem;
}

.search-result-name {
  width: calc(min(30rem, 30vw) - 4rem);
}
